import { css } from "styled-components";

export const typography = css`
  html {
    --font-size-default: clamp(1.6rem, 2.5vw, 2.2rem);
  }
  body {
    font-family: 'Quicksand', sans-serif;
    font-weight: var(--font-weight, 200);
    font-size: var(--font-size-default);
    line-height: var(--line-height, 1.8);
    text-align: var(--text-align, center);
    letter-spacing: normal;
    color: var(--color-gray);
  }
`