import { useStaticQuery, graphql } from 'gatsby'

const query = graphql`
  {
    allMdx(
      filter: { frontmatter: { type: { eq: "program" } } }
      sort: { fields: frontmatter___level, order: ASC }
    ) {
      nodes {
        frontmatter {
          title
          grade
          schedules {
            name
            place
            time
          }
          test
        }
      }
    }
  }
`

export const useProgramQuery = () => {
  const data = useStaticQuery(query)

  return data.allMdx.nodes
}
