import { css } from 'styled-components'
// import { mq } from './mdQuery'

export const reset = css`
  *,
  * + *,
  *::before,
  *::after {
    margin: 0;
    border: 0;
    padding: 0;
    box-sizing: border-box;
    vertical-align: baseline;
  }

  html {
    width: 100vw;
    font-size: 62.5%; /* 1rem = 10px */
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  ul,
  ol {
    list-style: none;
  }

  input,
  select,
  progress {
    appearance: none;
  }

  svg {
    fill: currentColor;
    width: var(--size, 100%);
    height: var(--size, 100%);
  }
`
    /* font-size: 43.75%;  1rem = 7px */
    /* ${ mq.md`font-size: 50%;`} 1rem = 8px */
    /* ${ mq.md`font-size: 56.25%;`} 1rem = 9px */
    /* ${ mq.lg`font-size: 62.5%;`} 1rem = 10px */
    /* ${ mq.xl`font-size: 87.5%;`} 1rem = 14px */