import React, { useState, useEffect } from 'react'
import { navigate } from 'gatsby'

import { MdMenu, MdClose } from 'react-icons/md'
import { IoMdLogOut } from 'react-icons/io'
import { FaFacebookF } from 'react-icons/fa'

import {
  ButtonExtLink,
  IconLink,
  TextLink,
} from 'components/Shared/_Links'

import { handleLogout } from 'services/auth.js'
import { memberLinks } from 'constant/links'

//#region STYLES
import styled from 'styled-components'
const Wrapper = styled.nav`
  ${p => p.theme.flex}
  color: var(--color-white);

  .link {
    --size: clamp(2rem, 3vw, 3rem);
    --padding: 0;
    --border-radius: 0;
    --background-color: transparent;
    --hover-background-color: var(--color-primary);
    --color: var(--color-white);

    &:not(:last-child) {
      margin-right: clamp(0.5rem, 2vw, 2rem);
    }
    
  }
  .subscribe {
      --padding: 0 1rem;
    }
`
const MenuWrapper = styled.nav`
  position: fixed;
  z-index: 2;
  top: 0;
  right: 0;
  width: 30rem;
  height: 100vh;
  padding-top: 22rem;
  background-color: var(--color-white);
  opacity: 1;
  box-shadow: 0 0.5rem 1.2rem var(--color-black); /* check filter */
  transition: width 1s;
  overflow: auto;

  .menu-item {
    --background-color: transparent;
    --color: var(--color-primary);
    --border-radius: 0;

    &:hover {
      --background-color: var(--color-primary);
      --color: var(--color-white);
      .icon {
        fill: var(--color-white);
        path {
          stroke: var(--color-white);
        }
      }
    }

    --justify-content: flex-start;
    ${ p => p.theme.flex}
    width: 100%;
    padding: clamp(1.4rem, 2vw, 1.7rem) 4rem;
    color: var(--color);
    background-color: var(--background-color);
    font-size: clamp(1.6rem, 3vw, 1.8rem);
    transition: background 1s;

    .icon {
      --size: 3rem;
      fill: var(--color-primary);
      path {
        stroke: var(--color-primary);
      }
    }

    span {
      margin-left: 1rem;
      font-size: calc(var(--font-size-default) * 1);
    }
  }

  .close {
    --size: clamp(3rem, 5vw, 5rem);
    --padding: 0;
    --border-radius: 50%;
    --background-color: transparent;
    --color: var(--color-primary);
    --hover-background-color: var(--color-primary);
    --hover-color: var(--color-white);
    position: absolute;
    top: 1rem;
    right: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
//#endregion


const Navbar = ({ location }) => {
  const [showNav, setShowNav] = useState(false)

  useEffect(() => {
    setShowNav(false)
  }, [location])

  const logout = _ => {
    handleLogout()
    return navigate('/')
  }

  return (
    <Wrapper>
      <ButtonExtLink
        href='http://eepurl.com/dFuQUk'
        target='_blank'
        inverse='true'
        className='link subscribe'
      >
        訂閱晶報
      </ButtonExtLink>
      <ButtonExtLink
        href='http://www.facebook.com/pages/jing-jing-r-tong-he-chang-tuan/144618842221350'
        target='_blank'
        className='link'
      >
        <FaFacebookF />
      </ButtonExtLink>
      <IconLink onClick={logout} className='link'>
        <IoMdLogOut>登出</IoMdLogOut>
      </IconLink>

      {!showNav ? (
        <IconLink onClick={e => setShowNav(true)} className='link'>
          <MdMenu />
        </IconLink>
      ) : (
        <>
          <MenuWrapper>
            <IconLink onClick={e => setShowNav(false)} className='close'>
              <MdClose />
            </IconLink>
            {memberLinks.map((link,i) => {
              return (
                <TextLink to={link.path} className='menu-item' key={`link-${i}`}>
                  {link.icon} <span>{link.label}</span>
                </TextLink>
              )
            })}
          </MenuWrapper>
        </>
      )}
    </Wrapper>
  )
}

export default Navbar
