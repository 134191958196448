import React from 'react'
import logo from 'assets/images/logo.svg'
//#region STYLES
import styled from 'styled-components'
const LogoBox = styled.a`
  ${p => p.theme.flex}
`
const Logo = styled.svg`
  --size: clamp(5.5rem, 7vw ,8rem);
  display: block;
  width: var(--size);
  height: var(--size);
  fill: var(--color-white);
  margin-right: var(--margin-right, 0.6rem);
`
const Span = styled.span`
  display: block;
  color: var(--color-white);
  line-height: 1.2;
`
const CnTitle = styled(Span)`
  font-family: 'Kaiti', cursive;
  font-size: clamp(1.6rem, 2vw, 3rem);
  letter-spacing: 0.3rem;
`
const EnTitle = styled(Span)`
  font-family: 'DancingScript', 'Dancing Script', cursive;
  font-size: clamp(1.6rem, 2vw, 2.6rem);
  /* letter-spacing: -0.1rem; */
`
//#endregion

const CCCLogo = ({location}) => {

  const href = location.pathname.includes('member') ? '/member' : '/'
  return (
    <LogoBox href={href}>
        <Logo viewBox='0 0 100 100'>
          <use xlinkHref={`${logo}#ccc-logo`}></use>
        </Logo>
        <h1>
          <CnTitle>晶晶兒童合唱團</CnTitle>
          <EnTitle>Crystal Children’s Choir</EnTitle>
        </h1>
      </LogoBox>
  )
}

export default CCCLogo
