/**
 * //#region Layout
 */
export const flex = `
  display: flex;
  flex-direction: var(--flex-direction, row);
  flex-wrap: var(--flex-wrap, nowrap);
  justify-content: var(--justify-content, space-between);
  align-items: var(--align-items, center );
`

export const grid = `
  display: grid;
  grid-template-columns: var(--grid-template-columns, repeat(12,1fr));
  grid-template-rows: var(--grid-template-rows, 1fr);
  grid-auto-rows: var(--grid-auto-rows, auto);
  grid-gap: var(--grid-gap, 0);
  justify-items: var(--justify-items, center );
  align-items: var(--align-items, center );
`
export const absCenter = `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
export const fullbleed = `--grid-column: 1/-1;`