import styled, {css} from "styled-components"

export const Button = styled.button`
  padding: var(--padding, 0.75rem clamp(1rem, 2vw, 3rem));
  /* padding: var(--padding, 0.75rem clamp(2rem, 4vw, 4rem)); */
  border-radius: var(--border-radius, 1rem);
  background-color: var(--background-color, var(--color-primary));
  color: var(--color, var(--color-white));
  cursor: pointer;
  font-size: var(--font-size, var(--font-size-default));
  
  &:hover {
    color: var(--hover-color, var(--color-white));
    background-color: var(--hover-background-color, var(--color-secondary));
    /* box-shadow: $btn-shadow-m; */
  }
  &:active
  &:focus {
    border: none;
  }

  ${p =>
    p.inverse &&
    css`
      --background-color: tranparent;
      border: 0.1rem solid var(--color-white);
    `}
`
export const IconButton = styled(Button)`
  --padding: 0;
  --background-color: transparent;
  --color: var(--color-gray-medium);
  --hover-color: var(--color-gray);
  width: var(--size);
  height: var(--size);
  --justify-content: center;
  ${ p => p.theme.flex}
`