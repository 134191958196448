import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.h2`
  margin: var(--margin-default) auto;
  margin-top: calc(var(--margin-default) * 2.5);
  font-size: clamp(3rem, 4.2vw, 4.2rem);
  font-weight: 700;
  letter-spacing: 0.05rem;
  color: transparent;
  background-image: var(--gradient-title);
  background-clip: text;
  -webkit-background-clip: text;
  text-align: center;

  span {
    display: block;
    margin-top: -2rem;
    ${ p => p.theme.mq.md`
      display: inline;
    `}
  }
`

const Title = ({ children }) => {
  return <Wrapper>{children}</Wrapper>
}

export default Title
