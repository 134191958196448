import React from 'react'

import Modal from 'components/Shared/Modal'

//#region STYLES
import styled from 'styled-components'
const Wrapper = styled.div`
  ${props => props.theme.absCenter}
  z-index: var(--z-modal-content);
  
  --width: 95vw;
  ${ p => p.theme.mq.md`
    --width: 75vw;
  `}
  width: var(--width);
  height: 95vh;

  iframe {
    width: 100%;
    height: 100%;
  }
`
//#endregion

const Contact = ({ showModal, closeModal }) => {
  
  return (
    <Modal showModal={showModal} closeModal={closeModal}>
        <Wrapper>
        <iframe
          title='聯絡晶晶'
          src='https://goo.gl/forms/aulLPVm29YFnwO4n2'
          frameBorder='0'
          allowFullScreen
        ></iframe>
      </Wrapper>
    </Modal>
  )
}

export default Contact
