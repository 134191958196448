import React from 'react'
import styled from "styled-components"

import Backdrop from 'components/Shared/Backdrop'

//#region STYLE
const ModalWrapper = styled.div`
  border: 2px solid green;
  z-index: var(--z-modal);
  ${props => props.theme.absCenter}
`
const ModalContent = styled.div`
  border: 2px solid blue;  
  /* width: 100%; */
  /* height: 100%; */
  z-index: var(--z-modal-content);
  ${props => props.theme.absCenter}
`
//#endregion

// TODO: Modal Styling from parents
// TODO: Close Button

const Modal = ({ style, showModal, closeModal, children }) => {

  // const onClick = e => {
  //   e.stopPropagation()
  //   closeModal()
  // }

  const onKeyDown = e => {
    if (e.key === 'Escape') closeModal()
  }

  const contentClick = e => {
    e.stopPropagation()
  }

  return (
    <>
      {showModal && (
        <Backdrop closeModal={closeModal}>
          <ModalWrapper style={style}>
            
            <ModalContent
              role='button'
              tabIndex={0}
              onClick={contentClick}
              onKeyDown={onKeyDown}
            >
              {children}
            </ModalContent>
          </ModalWrapper>
        </Backdrop>
      )}
    </>
  )
}

export default Modal
