// import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

const LinkStyle = css`
  display: inline-block;
  padding: var(--padding, 0.3rem clamp(2rem, 4vw, 4rem));
  background-color: var(--background-color, var(--color-primary));
  color: var(--color, var(--color-white));

  ${p =>
    p.inverse &&
    css`
      --background-color: tranparent;
      border: 0.1rem solid var(--color-white);
    `}

  &,
  &:link,
  &:visited {
    font-weight: 300;
    border-radius: var(--border-radius, 1.5rem);
    cursor: pointer;
    /* box-shadow: $btn-shadow-s; */
    transition: all 0.5s;
  }

  &:hover {
    color: var(--hover-color, var(--color-white));
    background-color: var(--hover-background-color, var(--color-secondary));
    /* box-shadow: $btn-shadow-m; */
  }

  &:active,
  &:focus {
    /* transform: translateY(-.1rem); */
    /* box-shadow: $btn-shadow-s; */
  }
`
const IconStyle = css`
  width: var(--size);
  height: var(--size);
`

export const ButtonLink = styled(Link)`
  ${LinkStyle}
  --justify-content: center;
  ${p => p.theme.flex}
`
export const ButtonExtLink = styled.a`
  ${LinkStyle}
`
export const IconLink = styled.a`
  --padding: 0;
  ${LinkStyle}
  ${IconStyle}
  display: flex;
  justify-content: center;
  align-items: center;
`

const TextLinkStyle = css`
  --padding: 0;
  --background-color: transparent;
  --hover-background-color: transparent;
  --hover-color: var(--color-secondary);
`
export const TextLink = styled(Link)`
  ${TextLinkStyle}
  ${LinkStyle}
`
export const TextExtLink = styled.a`
  ${TextLinkStyle}
  ${LinkStyle}
`
