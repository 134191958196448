import React from 'react'
import Layout from 'components/Layout'
import { ThemeProvider } from 'styled-components'
import { mq } from 'styles/base/mdQuery'
import { flex, grid, absCenter, fullbleed } from 'styles/base/mixins'
/**
 * TO DO: import MDX provider and wrap it around Layout
 */

const theme = {
  mq,
  flex,
  grid,
  absCenter,
  fullbleed,
}

// export const wrapRootElement = ({ element, props }) => {
//   console.log('Browser Root Element ', props)
//   return { element }
// }

export const wrapPageElement = ({ element, props }) => {
  return (
    <ThemeProvider theme={theme}>
      <Layout {...props}>{element}</Layout>
    </ThemeProvider>
  )
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (location && location.state)
    location.state.prevUrl = prevLocation ? prevLocation.pathname : null
}
