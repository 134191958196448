/*
 * GLOBAL VARIABLES
 */

import { css } from 'styled-components'

export const variable = css`
  html {
    /* #region COLORS */
    --color-primary: #8d43ad;
    --color-primary-rgb: 141, 67, 173;
    --color-primary-light: #734b6d;
    --color-primary-light-rgb: 115, 75, 109;
    --color-primary-light-1: #aea8d3;

    --color-secondary: #e87e04;
    --color-tertiary: #4ecdc4;

    --color-gray: #657281;
    /* --color-gray: #6c7a89; */
    --color-gray-dark: #d4d1d6;
    --color-gray-medium: #e5dfe7;
    --color-gray-light: #eeecef;

    --color-white: #ecf0f1;
    --color-black: #000;
    --color-beige: #f9f9f3;
    --color-beige-rgb: 249, 249, 243;
    --color-alert: #9a2189;

    /*
     *  #fab1a0
     *  hotpink
     *  #97c92c
     *  #429bd7
     *  #ab5fdd
     *  #efc64e
     *  #a6902b
     */
    --color-lilliput: 250, 177, 160;     
    --color-rainbow: 255, 105, 180;
    --color-green: 151, 201, 44;
    --color-blue: 66, 155, 215;
    --color-purple: 171, 95, 221;
    --color-concert: 239, 198, 78;
    --color-youth: 166, 144, 43;
    /* #endregion */

    /* #region GRADIENT COLORS */
    --gradient-bg: linear-gradient(to bottom, purple, var(--color-primary));
    --gradient-btn: linear-gradient(to bottom, var(--color-beige), var(--color-gray-dark) 99%);
    --gradient-btn-hover: linear-gradient(to bottom, var(--color-gray-dark), var(--color-beige));
    --gradient-title: linear-gradient(
      to bottom right,
      hotpink,
      var(--color-primary)
    );
    --gradient-cta: linear-gradient(
      to bottom right,
      rgba(var(--color-primary-rgb), 0.75),
      rgba(var(--color-primary-light-rgb), 0.5)
    );
    --gradient-lilliput: linear-gradient(
      to bottom right,
      rgba(var(--color-lilliput), 0.8),
      rgba(var(--color-primary-rgb), 0.8)
    );
    --gradient-rainbow: linear-gradient(
      to bottom right,
      rgba(var(--color-rainbow), 0.8),
      rgba(var(--color-primary-rgb), 0.8)
    );
    --gradient-green: linear-gradient(
      to bottom right,
      rgba(var(--color-green), 0.8),
      rgba(var(--color-primary-rgb), 0.8)
    );
    --gradient-blue: linear-gradient(
      to bottom right,
      rgba(var(--color-blue), 0.8),
      rgba(var(--color-primary-rgb), 0.8)
    );
    --gradient-purple: linear-gradient(
      to bottom right,
      rgba(var(--color-purple), 0.8),
      rgba(var(--color-primary-rgb), 0.8)
    );
    --gradient-concert: linear-gradient(
      to bottom right,
      rgba(var(--color-concert), 0.8),
      rgba(var(--color-primary-rgb), 0.8)
    );
    --gradient-youth: linear-gradient(
      to bottom right,
      rgba(var(--color-youth), 0.8),
      rgba(var(--color-primary-rgb), 0.8)
    );
    /* #endregion */

    /* #region LAYOUT */
    --z-backdrop: 20;
    --z-modal: 30;
    --z-modal-content: 40;

    --margin-default: clamp(3rem, 4vw, 4rem);
    --padding-side: clamp(2rem, 8vw, 15rem);
    
    --paragraph-width: clamp(18ch, 55ch, 100%);
    --header-height: 9rem;
    ${p => p.theme.mq.md`
      --header-height: 12rem;
    `}
    ${p => p.theme.mq.lg`
      --header-height: 14rem;
    `}
    /* #endregion */

    --box-shadow: 0 0.3rem 0.5rem rgba(0,0,0,0.1);
    /* --box-shadow: 0 0.6rem 0.7rem rgba(0,0,0,0.2); */
  }
`
