import { css } from 'styled-components'

const breakpoints = {
  xs: '480px',
  sm: '768px',
  md: '992px',
  lg: '1200px',
  xl: '1400px',
}

export const mq = Object.keys(breakpoints).reduce((acc, breakpoint) => {
  acc[breakpoint] = (...args) => css`
    @media only screen and (min-width: ${breakpoints[breakpoint]}) {
      ${css(...args)}
    }
  `
  return acc
}, {})
