import React from 'react'

import Logo from 'components/Layout/Logo'
import Nav from './Navbar'

//#region STYLES
import styled from 'styled-components'
const Wrapper = styled.header`
  ${p => p.theme.flex};
  height: var(--header-height);
  padding: clamp(1rem, 2vw, 2rem) var(--padding-side);
  background-color: rgba(var(--color-primary-rgb), 0.8);
  background-image: var(--gradient-bg); 
  * {
    font-size: calc(var(--font-size-default) * 0.8);
  }
  button {
    margin-left: 1rem;
  }
`
//#endregion

const Header = ({location}) => {
  return (
    <Wrapper>
      <Logo location={location} />
      <Nav location={location} />
    </Wrapper>
  )
}

export default Header
