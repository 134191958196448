import React from 'react'

import Modal from 'components/Shared/Modal'

import styled from 'styled-components'
const Wrapper = styled.div`
  ${props => props.theme.absCenter}
  --width: 95vw;
  ${ p => p.theme.mq.md`
    --width: 75vw;
  `}
  width: var(--width);
  height: 65vh;
  z-index: var(--z-modal-content);

  iframe {
    width: 100%;
    height: 100%;
  }
`

const Map = ({ showModal, closeModal }) => {
  return (
    <Modal showModal={showModal} closeModal={closeModal}>
      <Wrapper>
        <iframe
          title='晶晶辦公室'
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1135.743915923495!2d121.00449841918612!3d24.779597234802715!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3468361942955555%3A0x8118c880c647bcee!2z5pm25pm25YWS56ul5ZCI5ZSx5ZyY!5e0!3m2!1szh-TW!2stw!4v1521510133069'
          frameBorder='0'
          allowFullScreen
        ></iframe>
      </Wrapper>
    </Modal>
  )
}

export default Map
