import React, { useState } from 'react'
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md'

import { Button } from './_Button'

import styled from 'styled-components'
const Question = styled.dt`
  .question {
    --background-color: transparent;
    --hover-background-color: var(--color-primary);
    --color: var(--color-gray);
    --background-image: var(--gradient-btn);
    &:hover {
    --background-image: none;

    }

    width: 100%;
    height: calc(var(--font-size-default) * 3);
    ${p => p.theme.flex}
    font-size: var(--font-size-default);
    margin-bottom: calc(var(--font-size-default) * 1.2);
    background-image: var(--background-image);
    

    span {
      display: inline-block;
      --size: calc(var(--font-size-default) * 2);
      width: var(--size);
      height: var(--size);
      opacity: 0.6;
    }
  }
  .active {
    --background-color: var(--color-primary);
    --color: var(--color-white);
    background-image: none;
  }
`
const Answer = styled.dd`
  display: block;
  & {
    --text-align: left;
  }
  --visibility: none;
  .active {
    --visibility: visible;
  }
  visibility: var(--visibility);
  width: 90%;
  margin: 2.5rem auto;
  text-align: left;
`

const Dropdown = ({ title, children }) => {
  const [show, setShow] = useState(false)
  const onClick = () => {
    setShow(!show)
  }

  return (
    <>
      <Question>
        <Button
          onClick={onClick}
          className={`question ${show ? 'active' : undefined}`}
        >
          {title}
          <span>
            {show ? (
              <MdArrowDropUp />
            ) : (
              <MdArrowDropDown />
            )}
          </span>
        </Button>
      </Question>

      {show && <Answer className={`answer ${show ? 'active' : undefined}`} >{children}</Answer>}
    </>
  )
}

export default Dropdown
