import React from 'react'

import styled from 'styled-components'
const Wrapper = styled.footer`
  background-image: var(--gradient-bg); 
  color: var(--color-white);
  padding: clamp(1rem, 2vw, 2rem) var(--padding-side);
`

const Footer = () => {
  return (
    <Wrapper>
      <p>
        &copy;{new Date().getFullYear()}
        <span>晶晶兒童合唱團</span>
      </p>
    </Wrapper>
  )
}

export default Footer
