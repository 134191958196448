import React from 'react'
import { navigate } from 'gatsby'
import { isBrowser, isLoggedIn } from 'services/auth'

import MemberLayout from 'components/Layout/member'
import PublicLayout from 'components/Layout/public'

const Layout = ({ children, location }) => {
  if (isBrowser()) {
    if (!isLoggedIn() && location.pathname.includes('/member')) {
      navigate('/login')
      return null
    }
  }

  return isLoggedIn() ? (
    <MemberLayout location={location}>{children}</MemberLayout>
  ) : (
    <PublicLayout location={location}>{children}</PublicLayout>
  )
}

export default Layout
