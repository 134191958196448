import React from 'react'

import FooterLinks from './FooterLinks'

import styled from 'styled-components'
const Wrapper = styled.footer`
  padding: 0 var(--padding-side);
  background-image: var(--gradient-bg);
  color: var(--color-white);
  font-size: clamp(1.6rem, 1.1vw, 2rem);
  bottom: 0;

  p {
    margin-bottom: 2rem;
    border-top: 1px solid var(--color-white);
  }
`

const Footer = () => {
  return (
    <Wrapper>
      <FooterLinks />
      <p>
        &copy;{new Date().getFullYear()} 晶晶兒童合唱團 版權所有
      </p>
    </Wrapper>
  )
}

export default Footer
