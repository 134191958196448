import React, { useState } from 'react'

import { TextLink, TextExtLink } from 'components/Shared/_Links'
import Contact from 'components/Contact'
import Map from 'components/Map'

import { footerLinks } from 'constant/links'

//#region STYLES
import styled from 'styled-components'
const Wrapper = styled.nav`
  --grid-template-columns: 1fr;
  --align-items: start;
  --nav-width: 90%;
  --nav-margin-top: 0;
  --nav-margin-bottom: 1.5rem;

  ${p => p.theme.mq.sm`
    --grid-template-columns: 2fr 1fr;
    --nav-width: 100%;
    --nav-margin-top: 1.5rem;
    --nav-margin-bottom: 0;
    `}
  ${p => p.theme.mq.md`
    --grid-template-columns: 2.2fr 1.2fr 1.5fr;
    --nav-margin-top: 0;
  `}
  ${p => p.theme.grid}
  margin: 4.5rem 0;

  .nav {
    width: var(--nav-width);
    &:not(:last-child) {
      margin-bottom: var(--nav-margin-bottom);
    }
    &:last-child {
      margin-top: var(--nav-margin-top);
    }
  }
  h4 {
    text-align: left;
    font-size: clamp(1.6rem, 1.9vw, 2rem);
    margin-bottom: 1.5rem;
  }
  a {
    display: flex;
    align-items: center;

    span {
      margin-left: 0.95rem;
    }

    svg {
      --size: 2rem;
    }
  }
`
//#endregion

const FooterLinks = () => {
  const [showMap, setShowMap] = useState(false)
  const [showContact, setShowContact] = useState(false)

  const closeMap = () => setShowMap(false)
  const closeContact = () => setShowContact(false)

  return (
    <Wrapper>
      {footerLinks.map((link, i) => (
        <div key={`footer-link-${i}`} className='nav'>
          <h4>{link.title}</h4>
          <nav>
            {link.sub.map((sublink, i) => {
              return (
                <React.Fragment key={`footer-sub-${i}`}>
                  {sublink.link && (
                    <TextLink to={sublink.link !== 'null' ? sublink.link : '/'}>
                      {sublink.icon}
                      <span>{sublink.span}</span>
                    </TextLink>
                  )}

                  {sublink.href && (
                    <TextExtLink
                      href={sublink.href}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {sublink.icon}
                      <span>{sublink.span}</span>
                    </TextExtLink>
                  )}

                  {sublink.map && (
                    <TextExtLink onClick={e => setShowMap(true)}>
                      {sublink.icon}
                      <span>{sublink.span}</span>
                    </TextExtLink>
                  )}

                  {sublink.contact && (
                    <TextExtLink onClick={e => setShowContact(true)}>
                      {sublink.icon}
                      <span>{sublink.span}</span>
                    </TextExtLink>
                  )}
                </React.Fragment>
              )
            })}
          </nav>
        </div>
      ))}
      {showMap && <Map showModal={showMap} closeModal={closeMap} />}
      {showContact && (
        <Contact showModal={showContact} closeModal={closeContact} />
      )}
    </Wrapper>
  )
}

export default FooterLinks
