import React from 'react'

import { MdPhone, MdMail, MdLocationOn, MdQuestionAnswer } from 'react-icons/md'
import { BsFileText } from 'react-icons/bs'
// import { BsFillQuestionCircleFill, BsFileText } from 'react-icons/bs'
import { BiNews } from "react-icons/bi"
// import { BiNews, BiCalendarX, BiCalendar } from "react-icons/bi"
import { FaGlobe, FaFacebookF, FaHome } from 'react-icons/fa'
import { GrGallery } from 'react-icons/gr'
// import { GrGallery, GrCart } from 'react-icons/gr'
import { AiOutlineForm } from 'react-icons/ai'

export const publicLinks = [
  { path: '/', label: '首頁' },
  { path: '/about', label: '關於我們' },
  { path: '/programs', label: '課程介紹' },
  { path: '/join', label: '加入我們' },
  { path: '/gallery', label: '活動花絮' },
]

export const aboutLinks = [
  { path: '/about', label: '晶晶簡介' },
  { path: '/about/timeline', label: '團史簡要' },
  { path: '/about/staff', label: '組織及師資' },
]

export const programLinks = [
  { path: '/programs', label: '課程介紹' },
  { path: '/programs/camps', label: '晶晶營隊' },
]

export const joinLinks = [
  { path: '/join', label: '新生及插班' },
  { path: '/join/pre', label: '晶晶小小班' },
  { path: '/join/faq', label: '常見問題' },
]

export const footerLinks = [
  {
    title: '聯絡我們',
    sub: [
      {
        link: 'null',
        icon: <MdPhone className='icon' />,
        span: '(03)666-3010',
      },
      {
        href: 'mailto:crystalchoirnews@gmail.com',
        icon: <MdMail className='icon' />,
        span: 'crystalchoirnews@gmail.com',
      },
      {
        map: true,
        icon: <MdLocationOn className='icon' />,
        span: '新竹市工業東二路一號六樓610室',
      },
      {
        href:
          'https://www.facebook.com/profile.php?id=61557442465194&locale=zh_TW',
          // 'http://www.facebook.com/pages/jing-jing-r-tong-he-chang-tuan/144618842221350',
        icon: <FaFacebookF className='icon' />,
        span: '晶晶粉絲頁',
      },
    ],
  },
  {
    title: '晶晶資訊',
    sub: [
      // {
      //   contact: true,
      //   icon: <BsFillQuestionCircleFill className='icon' />,
      //   span: '詢問晶晶',
      // },
      {
        link: '/join/faq',
        icon: <MdQuestionAnswer className='icon' />,
        span: '常見問題',
      },
    ],
  },
  {
    title: '相關網站',
    sub: [
      {
        href: 'http://www.crystalchoir.org',
        icon: <FaGlobe className='icon' />,
        span: '美國晶晶兒童合唱團',
      },
    ],
  },
]


/*=============================================================================*/
/**
 * MEMBER SITE
 */
export const memberLinks = [
  { path: '/member', 
    label: '會員首頁', 
    icon: <FaHome className='icon' /> },
  {
    path: '/member/newsletters',
    label: '晶報',
    icon: <BiNews className='icon' />,
  },
  // {
  //   path: '/member/leavereq',
  //   label: '線上請假',
  //   icon: <BiCalendarX className='icon' />,
  // },
  // {
  //   path: '/member/shop/dress',
  //   label: '服裝訂購',
  //   icon: <GrCart className='icon' />,
  // },
  {
    path: '/member/policy',
    label: '團員政策',
    icon: <BsFileText className='icon' />,
  },
  {
    path: '/member/forms',
    label: '各式表格',
    icon: <AiOutlineForm className='icon' />,
  },
  {
    path: '/member/gallery',
    label: '活動相簿',
    icon: <GrGallery className='icon' />,
  },
  // {
  //   path: '/member/calendar',
  //   label: '行事曆',
  //   icon: <BiCalendar className='icon' />,
  // },
  // { path: '/member/faq', 
  //   label: '詢問晶晶', 
  //   icon: <MdQuestionAnswer className='icon' /> 
  // },
]

export const shopLinks = [
  { path: '/member/shop/dress', label: '女生團服訂購' },
  { path: '/member/shop/tshirt', label: '晶晶T恤訂購' },
  { path: '/member/shop/secondhand', label: '二手團服買賣' },
]

export const policyLinks = [
  { path: '/member/policy', label: '規章' },
  { path: '/member/policy/grade9_12', label: '國三高三團員政策' },
]