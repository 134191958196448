import React from 'react'

import Header from './Header'
import Footer from './Footer'

import GlobalStyle from 'styles/globalPublicStyle'

//#region STYLES
import styled from 'styled-components'
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`
//#endregion

const MemberLayout = ({ location, children }) => {
  return (
    <>
      <GlobalStyle />
      <Wrapper>
        <Header location={location} />
        {children}
        <Footer />
      </Wrapper>
    </>
  )
}

export default MemberLayout
