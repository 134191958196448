import React from 'react'
import { MdClose } from "react-icons/md"

import { IconLink } from 'components/Shared/_Links'

//#region STYLED
import styled from 'styled-components'
const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--color-white);
  opacity: 0.96;
  z-index: var(--z-backdrop);

  .close {
    --size: clamp(3rem, 5vw, 5rem);
    --background-color: transparent;
    --border-radius: 50%;
    --color: var(--color-primary);
    --hover-background-color: var(--color-primary);
    position: absolute;
    top: 2.2rem;
    right: 2.2rem;
    z-index: 1000;
  }
`
//#endregion

const Backdrop = ({ children, closeModal }) => {
  const onClick = () => closeModal()

  const onKeyDown = e => {
    if (e.key === 'Escape') closeModal()
  }

  return (
    <Wrapper role='button' tabIndex={0} onClick={onClick} onKeyDown={onKeyDown}>
      <IconLink className='close'>
        <MdClose  />
      </IconLink>
      {children}
    </Wrapper>
  )
}

export default Backdrop
