import jwt from 'jsonwebtoken'

const TOKEN = 'ccctoken'

export const isBrowser = _ => typeof window !== 'undefined'

const jwtToken = _ => {
  return jwt.sign(
    JSON.stringify({
      // Note: exp time in sec
      exp:
        Math.floor(Date.now() / 1000) + parseInt(process.env.JWT_EXPIRE) * 60,
      data: process.env.JWT_PAYLOAD,
    }),
    process.env.JWT_SECRET
  )
}

const setToken = _ => {
  const token = jwtToken()
  window.localStorage.setItem(TOKEN, token)
}

const verifyToken = _ => {
  if (isBrowser()) {
    const token = window.localStorage.getItem(TOKEN)

    if (token) {
      const data = jwt.verify(token, process.env.JWT_SECRET, (err, decoded) => {
        if (err) {
          if (err.name === 'TokenExpiredError') {
            window.localStorage.removeItem(TOKEN)
          }
          return null
        }
        return decoded
      })

      if (data && data.data === process.env.JWT_PAYLOAD) return true

      return false
    }
  }
  // TODO: uncomment in prod
  return false 
  // TODO: remove when done with dev
  // return true
}

const removeToken = _ => {
  window.localStorage.removeItem(TOKEN)
}
/**
 * TODO: refresh Token
 */

export const handleLogin = password => {
  if (password === process.env.MEMBER_PWD) {
    setToken()
    return true
  }
  return false
}

export const isLoggedIn = _ => verifyToken()

export const handleLogout = _ => removeToken()
