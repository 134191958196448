import React from 'react'

import Logo from 'components/Layout/Logo'
import Nav from './Navbar'

//#region STYLES
import styled from 'styled-components'
const Wrapper = styled.header`
  ${p => p.theme.flex}
  position: relative;
  z-index: 10;
  height: var(--header-height);
  padding: clamp(1rem, 2vw, 2rem) var(--padding-side);
  background-image: ${p => (!p.home && 'var(--gradient-bg)')};
`
//#endregion

const Header = ({ location }) => {
  return (
    <Wrapper home={location.pathname === '/'}>
      <Logo location={location} />
      <Nav location={location} />
    </Wrapper>
  )
}

export default Header
