import { createGlobalStyle } from 'styled-components'

import { reset } from './base/reset'
import { variable } from './base/variables'
import { typography } from './base/typography'

const GlobalStyle = createGlobalStyle`
  ${reset};
  ${variable};
  ${typography};
`

export default GlobalStyle
