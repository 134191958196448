import React from 'react'
import { useProgramQuery } from 'hooks/useProgramQuery'

import styled from 'styled-components'
const Wrapper = styled.div`
  --column-count: 2;
  ${ p => p.theme.mq.md`
    --column-count: 3;
  `}
  column-count: var(--column-count);
  /* column-gap: 10px; */

  div {
    --grid-template-rows: 1fr auto;
    ${p => p.theme.grid}
    ${p => p.theme.fullbleed}
    break-inside: avoid;
    padding-left: 1rem;
    border-left: 5px solid var(--color-tertiary);
    margin-bottom: calc(var(--font-size-default)*2);

    & * {
      text-align: left;
      width: 100%;
    }

    h3 {
      color: var(--color-primary);
      opacity: 0.9;
      width: 100%;
      border-bottom: 1px solid var(--color-primary);
    }
    p {
      --margin-top: 0;
      --margin-bottom: 0;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  
`


export const ProgramsGrade = () => {
  const programs = useProgramQuery()
  return (
    <Wrapper>
      {programs.map((program, i) => {
        const { title, grade } = program.frontmatter
        return (
          <div key={`grade-${i}`}>
            <h3>{title}</h3>
            <p>{grade}</p>
          </div>
        )
      })}
    </Wrapper>
  )
}


export const ProgramsTest = () => {
  const programs = useProgramQuery()
  return (
    <Wrapper>
      {programs.map((program, i) => {
        const { title, test } = program.frontmatter
        return (
          <div key={`test-${i}`}>
            <h3>{title}</h3>
            <ul>
              {test.map((item,i) => (
                <li key={`test-item=${i}`}>{item}</li>
              ))}
            </ul>
          </div>
        )
      })}
    </Wrapper>
  )
}

export const ProgramsSchedule = () => {
  const programs = useProgramQuery()
  return (
    <Wrapper>
      {programs.map((program, i) => {
        const { title, schedules } = program.frontmatter
        return (
          <div key={`schedule-${i}`}>
            <ul>
              {schedules.map((schedule,i) => {
                return (
                  <React.Fragment key={`time-${i}`}>
                    <h3>{schedule.name ? schedule.name : title}</h3>
                    <p>{schedule.place}</p>
                    <p>{schedule.time}</p>
                  </React.Fragment>
                )
              })}
            </ul>
          </div>
        )
      })}
    </Wrapper>
  )
}

const events = [
  {
    time: '一月',
    events: ['插班生招生', '冬令音樂營'],
  },
  {
    time: '六-七月',
    events: ['年度招生(1)', '公演', '演唱之旅'],
  },
  {
    time: '八月',
    events: ['年度招生(2)', '夏令音樂營'],
  },
  {
    time: '九月',
    events: ['新生報到'],
  },
  {
    time: '十二月',
    events: ['團慶', '歲末音樂會'],
  },
]

export const ProgramsEvents = _ => {
  return (
    <Wrapper>
      {events.map((event, i) => {
        return (
          <div key={`events-${i}`}>
            <h3>{event.time}</h3>
            {event.events.map((ev,i) => (
              <p key={`event-${i}`}>{ev}</p>
            ))}
          </div>
        )
      })}
    </Wrapper>
  )
}
