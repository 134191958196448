import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'

import { MdAccountCircle, MdMenu, MdClose } from 'react-icons/md'
import { ButtonLink, IconLink } from 'components/Shared/_Links'

import { publicLinks } from 'constant/links'

//#region STYLES
import styled from 'styled-components'
const Wrapper = styled.nav`
  ${p => p.theme.flex}
  color: var(--color-white);

  .link {
    --size: clamp(3rem, 5vw, 5rem);
    --padding: 0;
    --border-radius: 0;
    --background-color: transparent;
    --hover-background-color: var(--color-primary);
    --color: var(--color-white);
    
    &:not(:last-child) {
      margin-right: clamp(0.5rem, 0.6vw, 1rem);
    }
  }
  /* svg {
    --size: 100%;
  } */
`
const MenuWrapper = styled.nav`
  position: fixed;
  top: 0;
  right: 0;
  width: 30rem;
  height: 100vh;
  padding-top: 22rem;
  background-color: var(--color-white);
  opacity: 1;
  box-shadow: 0 0.5rem 1.2rem var(--color-black); /* check filter */
  transition: width 1s;
  overflow: auto;

  .menu-item {
    --background-color: transparent;
    --color: var(--color-primary);
    &:hover {
      --background-color: var(--color-primary);
      --color: var(--color-white);
    }
    display: block;
    width: 100%;
    padding: clamp(1.4rem, 2vw, 1.7rem) 4rem;
    color: var(--color);
    background-color: var(--background-color);
    font-size: clamp(1.6rem, 3vw, 1.8rem);
    transition: background 1s;
  }
  .close {
    --size: clamp(3rem, 5vw, 5rem);
    --padding: 0;
    --border-radius: 50%;
    --background-color: transparent;
    --color: var(--color-primary);
    --hover-background-color: var(--color-primary);
    --hover-color: var(--color-white);
    position: absolute;
    top: 1rem;
    right: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
//#endregion

const Nav = ({ location }) => {
  const [showNav, setShowNav] = useState(false)

  useEffect(() => {
    setShowNav(false)
  }, [location])

  return (
    <Wrapper>
      <ButtonLink to='/login' className='link'>
        <MdAccountCircle />
      </ButtonLink>

      {!showNav ? (
        <IconLink onClick={e => setShowNav(true)} className='link'>
          <MdMenu />
        </IconLink>
      ) : (
        <>
          <MenuWrapper>
            <IconLink onClick={e => setShowNav(false)} className='close'>
              <MdClose />
            </IconLink>
            { publicLinks.map(link => {
                return (
                  <Link key={link.label} to={link.path} className='menu-item'>
                    {link.label}
                  </Link>
                )
            })}
          </MenuWrapper>
        </>
      )}
    </Wrapper>
  )
}

export default Nav
